import { withPreview } from 'gatsby-source-prismic'
import React from 'react'
import Layout from '../components/Layout'

const Error404Page: React.FC = () => (
  <>
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </>
)

export default withPreview(Error404Page)
